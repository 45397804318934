import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { DynamicFormField } from '@teamfoster/dynamic-forms';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DictionaryNgrxModule, FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-question-range',
  standalone: true,
  imports: [MatSliderModule, ReactiveFormsModule, MatFormFieldModule, DictionaryNgrxModule, CommonModule, MatInputModule],
  templateUrl: './question-range.component.html',
  styleUrl: './question-range.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionRangeComponent implements OnInit, OnDestroy {
  cd = inject(ChangeDetectorRef);

  config!: DynamicFormField;
  group!: UntypedFormGroup;

  private lang = inject(LanguageService);
  dict = new FromDictionaryPipe(this.lang);

  private _unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.group
      ?.get(this.config.name)
      ?.statusChanges.pipe()
      .subscribe(e => {
        this.cd.markForCheck();
      });
  }

  isRequired(ctrl: AbstractControl<any, any> | null) {
    if (!ctrl || !ctrl.validator) {
      return false;
    }
    const validator = ctrl?.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
