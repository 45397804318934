<div class="question" [formGroup]="group" [class.has--error]="group.get(config.name)?.invalid">
  @if (config['title']) {
    <h2 class="h4 text-primary">
      {{ config['title'] }}
      @if (isRequired(group.get(config.name))) {
        <span class="required">*</span>
      }
    </h2>
  }

  @if (config['intro']) {
    <p class="mb-md-4">{{ config['intro'] }}</p>
  }

  @if (config.label) {
    <strong class="question__label d-inline-block mb-2 text-error-color">
      {{ config.label }}
      @if (isRequired(group.get(config.name))) {
        *
      }
    </strong>
  }

  <div class="clip-shadow">
    <div class="card triangle-clip shadow-sm card-body">
      <mat-slider class="mb-3" min="0" max="6" step="1" showTickMarks>
        <input #slider matSliderThumb value="3" [formControlName]="config.name" />

        <ul class="slider-track-annotations">
          <li class="slider-track-annotations__item">{{ 'vraag-slider-waarde-minimaal-label' | fromDictionary }}</li>
          <li class="slider-track-annotations__item">{{ 'vraag-slider-waarde-midden-label' | fromDictionary }}</li>
          <li class="slider-track-annotations__item">{{ 'vraag-slider-waarde-maximaal-label' | fromDictionary }}</li>
        </ul>
      </mat-slider>
    </div>
  </div>
</div>
