<div class="question question-open" [formGroup]="group" [class.has--error]="group.get(config.name)?.invalid">
  @if (config['title']) {
    <h2 class="h4 text-primary">
      {{ config['title'] }}
      @if (isRequired(group.get(config.name))) {
        <span class="required">*</span>
      }
    </h2>
  }
  @if (config['intro']) {
    <p class="mb-md-4">{{ config['intro'] }}</p>
  }

  @if (config.label) {
    <strong class="question__label d-inline-block mb-2 text-error-color">{{ config.label }}</strong>
  }
  <mat-form-field
    *ngIf="group.get(config.name) as field"
    [appearance]="config.appearance || 'fill'"
    [color]="config.color || 'accent'"
    [subscriptSizing]="config.subscriptSizing || 'fixed'"
    class="card bg-transparent"
  >
    <!--<mat-label [innerHTML]="config.label"></mat-label>-->
    <!--<div class="mat-form-field-subLabel" *ngIf="config.subLabel" [innerHTML]="config.subLabel"></div>-->

    <textarea
      matInput
      [formControlName]="config.name"
      [autocomplete]="config.autocomplete"
      [placeholder]="config.placeholder || ''"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      rows="5"
      cdkAutosizeMinRows="5"
      cdkAutosizeMaxRows="15"
    ></textarea>

    <mat-hint *ngIf="config.hint" align="start">{{ config.hint }}</mat-hint>

    <mat-error *ngIf="field.invalid">
      {{ 'vragenlijst-antwoord-verplicht-melding' | fromDictionary }}
    </mat-error>
  </mat-form-field>
</div>
