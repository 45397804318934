import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgZone, ViewChild } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormField, FormErrorPipe } from '@teamfoster/dynamic-forms';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { take } from 'rxjs';
import { QuestionBaseComponent } from '../question-base/question-base.component';

@Component({
  selector: 'app-question-open',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule, DictionaryNgrxModule],
  templateUrl: './question-open.component.html',
  styleUrl: './question-open.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionOpenComponent extends QuestionBaseComponent {
  constructor(private _ngZone: NgZone) {
    super();
  }

  @ViewChild('autosize') autosize?: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize?.resizeToFitContent(true));
  }
}
