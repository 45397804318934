<div class="question" [formGroup]="group" [class.has--error]="group.get(config.name)?.invalid">
  @if (config['title']) {
    <h2 class="h4 text-primary">
      {{ config['title'] }}
      @if (isRequired(group.get(config.name))) {
        <span class="required">*</span>
      }
    </h2>
  }

  @if (config['intro']) {
    <p class="mb-md-4">{{ config['intro'] }}</p>
  }

  @if (config.label) {
    <strong class="question__label d-inline-block mb-2 text-error-color">
      {{ config.label }}
      @if (isRequired(group.get(config.name))) {
        *
      }
    </strong>
  }

  <div class="clip-shadow">
    <div class="card triangle-clip card-body">
      @if (config['multipleAnwsersPossible']) {
        <input matInput [formControlName]="config.name" [hidden]="true" />

        <ul class="multiple-choice-list m-n2" [formGroup]="checkboxes">
          @for (option of config.selectOptions; track option.id) {
            <li class="multiple-choice-list__item">
              <mat-checkbox [formControlName]="option.id">{{ option.title }}</mat-checkbox>
            </li>
          }
        </ul>
      } @else {
        <mat-radio-group [formControlName]="config.name">
          <ul class="multiple-choice-list m-n2">
            @for (option of config.selectOptions; track option.id) {
              <li class="multiple-choice-list__item">
                <mat-radio-button [value]="[option.id]" [checked]="group.get(config.name)?.value?.includes(option.id)">
                  {{ option.title }}
                </mat-radio-button>
              </li>
            }
          </ul>
        </mat-radio-group>
      }
    </div>
  </div>
</div>

<!-- <pre>{{ group.get(config.name)?.value | json }}</pre> -->
