import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { QuestionBaseComponent } from '../question-base/question-base.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { takeUntil } from 'rxjs';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'app-question-multiple-choice',
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, DictionaryNgrxModule, CommonModule, MatCheckboxModule, MatRadioModule],
  templateUrl: './question-multiple-choice.component.html',
  styleUrl: './question-multiple-choice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionMultipleChoiceComponent extends QuestionBaseComponent implements OnInit {
  fb = inject(UntypedFormBuilder);
  checkboxes = this.fb.group({});

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.config['multipleAnwsersPossible']) {
      this.initCheckboxes();
    }
  }

  initCheckboxes() {
    // add control for each option
    this.config['selectOptions']?.map(option => {
      const initialValue = this.config.value && this.config.value.includes(option.id);
      this.checkboxes.addControl(option.id, this.fb.control(initialValue));
    });

    // Update checkboxgroup value to array of selected checkboxes
    this.checkboxes.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe(val => {
      this.group.get(this.config.name)?.patchValue(
        Object.keys(val)
          .filter(key => val[key])
          .map(Number)
      );
    });

    // If value changes outside component (patchvalue) set checkboxvalues
    this.group.get(this.config.name)?.valueChanges.subscribe(values => {
      if (!values?.length) {
        return;
      }
      values.forEach((name: string) => {
        const ctrl = this.checkboxes.controls[this.controlName(name)];
        if (ctrl) {
          ctrl.setValue(true);
        }
      });
    });
  }

  controlName(id: number | string): string {
    return `${this.config.name}_option_${id}`;
  }

  get showError() {
    const field = this.group.get(this.config.name);
    return field?.invalid && (field?.touched || field?.dirty || this.checkboxes.touched || this.checkboxes.dirty);
  }
}
