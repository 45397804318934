import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { CheckAuthGuard } from './auth/guards/checkAuth.guard';
import { AuthGuard } from './auth/guards/auth.guard';

const appGuards = [CookieSettingsGuard, DictionaryGuard, MenuGuard, CheckAuthGuard];

const routes: Routes = [
  //{
  //  path: '',
  //  loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  //  pathMatch: 'full',
  //  canActivate: [...appGuards, AuthGuard],
  //},
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/vragenlijsten',
  },
  {
    path: 'account',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [...appGuards],
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.routes').then(m => m.routes),
    canActivate: [],
  },
  {
    path: 'pagina',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
  },
  {
    path: 'vragenlijsten',
    loadChildren: () => import('./questionnaires/questionnaires.module').then(m => m.QuestionnairesModule),
    canActivate: [...appGuards, AuthGuard],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
