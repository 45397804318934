import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, Pipe, inject, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import * as i1$1 from '@ngrx/store';
import { createAction, props, createReducer, on, createFeatureSelector, createSelector, Store, StoreModule } from '@ngrx/store';
import * as i2 from '@ngrx/effects';
import { createEffect, ofType, Actions, EffectsModule } from '@ngrx/effects';
import { switchMap, map, catchError, tap, filter, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommonModule } from '@angular/common';

/**
 * This is not a real service, but it looks like it from the outside.
 * It's just an InjectionTToken used to import the config object, provided from the outside
 */
const MenuConfigService = new InjectionToken('MenuConfig');
class MenuService {
  get _apiUrl() {
    if (!this.config.apiUrl) return `${this.BASE_URL || ''}api/menu`;
    if (this.isAbsolute(this.config.apiUrl)) {
      return this.config.apiUrl;
    }
    return `${this.BASE_URL || ''}${this.config.apiUrl}`;
  }
  constructor(Http, BASE_URL, config) {
    this.Http = Http;
    this.BASE_URL = BASE_URL;
    this.config = config;
    this.menu = {};
  }
  loadMenu() {
    return this.Http.get(this._apiUrl);
  }
  init() {}
  isAbsolute(url) {
    var pattern = /^https?:\/\//i;
    return pattern.test(url);
  }
  static {
    this.ɵfac = function MenuService_Factory(t) {
      return new (t || MenuService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject('BASE_URL', 8), i0.ɵɵinject(MenuConfigService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MenuService,
      factory: MenuService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.HttpClient
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: ['BASE_URL']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MenuConfigService]
      }]
    }];
  }, null);
})();
class FromMenuPipe {
  constructor(menuService) {
    this.menuService = menuService;
  }
  transform(value, languageCode) {
    if (!value) {
      return value;
    }
    if (!this.menuService.menu || !this.menuService.menu[value.toUpperCase()]) {
      return {
        error: 'MENU NOT FOUND FOR: ' + value
      };
    }
    var items = this._removeUnpublished([...this.menuService.menu[value.toUpperCase()].menu]);
    if (languageCode) {
      items = this._setLanguageLabel(items, languageCode);
    }
    return items;
  }
  _removeUnpublished(items) {
    items = [...items.filter(i => i.publish)];
    items.forEach((item, i) => {
      if (item.menuItems?.length > 0) {
        item = {
          ...item,
          menuItems: [...this._removeUnpublished(item.menuItems)]
        };
      }
      items[i] = item;
    });
    return [...items];
  }
  _setLanguageLabel(items, languageCode) {
    items.forEach((item, i) => {
      item = {
        ...item,
        title: item.translations?.[languageCode] || item.title
      };
      if (item.menuItems?.length > 0) {
        item = {
          ...item,
          menuItems: [...this._setLanguageLabel(item.menuItems, languageCode)]
        };
      }
      items[i] = item;
    });
    return [...items];
  }
  static {
    this.ɵfac = function FromMenuPipe_Factory(t) {
      return new (t || FromMenuPipe)(i0.ɵɵdirectiveInject(MenuService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "fromMenu",
      type: FromMenuPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FromMenuPipe, [{
    type: Pipe,
    args: [{
      name: 'fromMenu'
    }]
  }], function () {
    return [{
      type: MenuService
    }];
  }, null);
})();
const LoadMenu = createAction('[Menu] Load Menu');
const LoadMenuEntriesSuccess = createAction('[Menu] Load Menu Entries Success', props());
const LoadMenuSuccess = createAction('[Menu] Load Menu Success', props());
const LoadMenuFail = createAction('[Menu] Load Menu Fail', props());
const initialState = {
  entities: {},
  loaded: false,
  loading: false
};
const MenuReducer = createReducer(initialState, on(LoadMenuSuccess, state => {
  return {
    ...state,
    loading: true
  };
}), on(LoadMenuEntriesSuccess, (state, {
  entries
}) => {
  const entities = entries.reduce((dictEntries, entry) => {
    return {
      ...dictEntries,
      [entry.identifier.toUpperCase()]: entry
    };
  }, {
    ...state.entities
  });
  return {
    ...state,
    entities
  };
}), on(LoadMenuSuccess, (state, {
  entries
}) => {
  return {
    ...state,
    loading: false,
    loaded: true
  };
}), on(LoadMenuFail, (state, {
  error
}) => {
  return {
    ...state,
    loading: false
  };
}));
const getMenuEntities$1 = state => state.entities;
const getMenuLoading$1 = state => state.loading;
const getMenuLoaded$1 = state => state.loaded;
const reducers = {
  entries: MenuReducer
};
const getMenuState = createFeatureSelector('menu');
const loadMenu$ = createEffect((actions$ = inject(Actions), service = inject(MenuService)) => {
  return actions$.pipe(ofType(LoadMenu), switchMap(() => service.loadMenu().pipe(map(entries => LoadMenuEntriesSuccess({
    entries
  })), catchError(error => of(LoadMenuFail({
    error
  }))))));
}, {
  functional: true
});
const loadMenuEntriesSuccess$ = createEffect((actions$ = inject(Actions), service = inject(MenuService)) => {
  return actions$.pipe(ofType(LoadMenuEntriesSuccess), tap(({
    entries
  }) => {
    const entities = entries.reduce((dictEntries, entry) => {
      return {
        ...dictEntries,
        [entry.identifier.toUpperCase()]: entry
      };
    }, {});
    service.menu = entities;
  }), map(({
    entries
  }) => LoadMenuSuccess({
    entries
  })));
}, {
  functional: true
});
const effects = {
  loadMenu$,
  loadMenuEntriesSuccess$
};
const getAllMenuState = createSelector(getMenuState, state => state.entries);
const getMenuEntities = createSelector(getAllMenuState, getMenuEntities$1);
const getMenuLoaded = createSelector(getAllMenuState, getMenuLoaded$1);
const getMenuLoading = createSelector(getAllMenuState, getMenuLoading$1);
const MenuGuard = () => {
  const store = inject(Store);
  return store.select(getMenuLoaded).pipe(tap(loaded => {
    if (!loaded) {
      store.dispatch(LoadMenu());
    }
  }), filter(loaded => loaded), take(1)).pipe(switchMap(() => of(true)), catchError(() => of(false)));
};
const storeFeature = StoreModule.forFeature('menu', reducers);
const effectsFeature = EffectsModule.forFeature(effects);
class MenuNgrxModule {
  static forRoot(config) {
    config = {
      apiUrl: 'api/menu',
      ...config
    };
    return {
      ngModule: MenuNgrxModule,
      providers: [{
        provide: MenuConfigService,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function MenuNgrxModule_Factory(t) {
      return new (t || MenuNgrxModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MenuNgrxModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, storeFeature, effectsFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuNgrxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, storeFeature, effectsFeature],
      declarations: [FromMenuPipe],
      exports: [FromMenuPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FromMenuPipe, LoadMenu, LoadMenuEntriesSuccess, LoadMenuFail, LoadMenuSuccess, MenuConfigService, MenuGuard, MenuNgrxModule, MenuService, effects, effectsFeature, getAllMenuState, getMenuEntities, getMenuLoaded, getMenuLoading, getMenuState, reducers, storeFeature };
